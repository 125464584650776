<template>
  <div class="mnotify_app__main_dashboard" :key="triggerRefreshOnComponent">
    <div
      v-if="showProfile"
      :class="[showProfile ? 'OverLay' : 'noOverlay']"
    ></div>
    <div v-if="startingTour" :class="[startingTour ? 'OverLay' : '']"></div>
    <!-- update profile -->
    <div class="update_profile" v-if="showProfile">
      <div class="text___">
        <p>
          {{ $t("dashboard.onboarding.update_profile.header") }}
        </p>
        <button @click="showModal">
          {{ $t("dashboard.onboarding.update_profile.button") }}
        </button>
      </div>
    </div>
    <!-- Verify Email -->
    <el-dialog
      :visible.sync="showEmailVerification"
      class="email-verification-text-container update-email-modal"
      :title="$t('dashboard.onboarding.verify_email.title')"
      :center="true"
      :lock-scroll="false"
      :width="dynamicWidthEmail"
    >
      <p>{{ $t("dashboard.onboarding.verify_email.header") }}</p>
      <span slot="footer" class="some-bg">
        <el-button
          class="verify-email-button"
          type="primary"
          @click="checkUserCameFromProfile"
        >
          {{ $t("dashboard.onboarding.verify_email.button") }}
        </el-button>
      </span>
      <el-dialog
        :visible.sync="updateEmailModal"
        title="Update Email"
        :center="true"
        class="center-all-modals update-email-modal"
        :append-to-body="true"
        :width="dynamicWidthEmail"
      >
        <div class="confirm-email-body">
          <p class="confirm-email-text">
            {{ $t("dashboard.onboarding.verify_email.enter_email") }}
          </p>
        </div>
        <el-divider></el-divider>
        <div class="email-form">
          <el-form
            :model="emailForm"
            :rules="emailRules"
            ref="emailForm"
            label-width="100px"
          >
            <el-form-item
              :label="$t('dashboard.onboarding.verify_email.form.email.label')"
              prop="email"
            >
              <el-input
                v-model="emailForm.email"
                :placeholder="
                  $t('dashboard.onboarding.verify_email.form.email.placeholder')
                "
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer">
          <el-button
            type="primary"
            @click="updateEmailModal = false"
            class="verify-email-button notranslate"
            >{{ $t("misc.button.cancel") }}</el-button
          >
          <el-button
            type="danger"
            @click="sendEmailForVerification"
            class="verify-email-button is-danger"
            >{{ $t("misc.button.confirm") }}</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        :title="$t('dashboard.onboarding.verify_email.verify_email_header')"
        :visible.sync="showVerifyEmail"
        :center="true"
        :append-to-body="true"
        :width="dynamicWidthEmail"
        class="center-all-modals"
      >
        <div class="confirm-email-body">
          <p class="confirm-email-text">
            {{ $t("dashboard.onboarding.verify_email.verify_link_success") }}
            <br />
            <span class="user-email">{{ emailForm.email }}</span>
            <br />
            {{ $t("dashboard.onboarding.verify_email.verify_link_check") }}
          </p>
        </div>
        <span slot="footer" class="verify-footer">
          <el-button
            type="primary"
            @click="goBackToChangeEmail"
            class="verify-email-button"
          >
            {{ $t("dashboard.onboarding.verify_email.change_email") }}
          </el-button>
          <el-button
            :loading="confirmationLoading"
            @click="checkedUserVerified"
            class="verify-email-button is-danger"
            >{{ $t("misc.button.verify") }}</el-button
          >
        </span>
      </el-dialog>
    </el-dialog>
    <!-- End -->
    <div class="mnotify__main_dashboard_header">
      <div class="logo_section">
        <!-- <img width="140px" class="add_logo" src="@/assets/logo.png" alt=""> -->
        <picture class="img-picture" style="width: fit-content">
          <source srcset="@/assets/img/bms-01.png" media="(max-width: 600px)" />
          <source
            srcset="@/assets/img/bms-01-text.png"
            media="(min-width: 601px)"
          />
          <img class="add_logo" src="@/assets/img/bms-01.png" alt="BMS logo" />
        </picture>
      </div>
      <div id="_top_nav_large" class="top_nav">
        <changeAppLanguage
          v-if="!checkIfMobile"
          style="position: relative; top: 0.5rem"
        />
        <div
          class="records sms-records"
          :class="{
            'balance-header': userInfo === 2,
          }"
          v-if="logintype == 'sms'"
        >
          <div class="icon" style="margin-inline: 1rem">
            <img src="@/assets/__message_sm.svg" alt="" />
          </div>
          <div class="text-container">
            <!-- <div class="wallet-balance-container">
              <p class="wallet-balance record-title" type="button">
                Wallet Balance <span>Ghc {{ walletBalance }}</span>
              </p>
              <p></p>
            </div> -->

            <div
              v-if="userInfo === 1"
              class="sms-balance"
              style="cursor: pointer"
              @click="
                $router.push('/wallet').catch((err) => {
                  if (err.name !== 'NavigationDuplicated') throw err;
                })
              "
            >
              <p class="record-title" style="font-weight: bold">
                {{ $t("dashboard.overview.sms_balance") }}
              </p>
              <p class="record-amount notranslate">
                {{ combinedBalance }}
              </p>
              <p
                style="font-size: xx-small; color: #f56c6c"
                class="notranslate"
              >
                {{ $t("dashboard.overview.expires_on") }} {{ expireDate }}
              </p>
            </div>
            <div
              class="individual-balance text-container"
              v-else-if="userInfo === 2"
              style="width: 15rem"
            >
              <div
                class="sms-balance"
                style="cursor: pointer"
                @click="
                  $router.push('/wallet').catch((err) => {
                    if (err.name !== 'NavigationDuplicated') throw err;
                  })
                "
              >
                <p class="record-title">Non-Expiry SMS</p>
                <p class="record-amount notranslate">
                  {{
                    ballance.sms_balance
                      ? ballance.sms_balance.toLocaleString()
                      : 0
                  }}
                </p>
              </div>
              <div
                class="bonus"
                style="cursor: pointer"
                @click="
                  $router.push('/wallet').catch((err) => {
                    if (err.name !== 'NavigationDuplicated') throw err;
                  })
                "
              >
                <p class="record-title">Expiry SMS</p>
                <p class="record-amount sms-bonus notranslate">
                  {{
                    ballance.sms_bonus ? ballance.sms_bonus.toLocaleString() : 0
                  }}
                </p>
                <p style="font-size: xx-small; color: #f56c6c">
                  {{ $t("dashboard.overview.expires_on") }} {{ expireDate }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style="cursor: pointer"
          @click="
            $router.push('/wallet').catch((err) => {
              if (err.name !== 'NavigationDuplicated') throw err;
            })
          "
          class="records"
          v-else
        >
          <div class="icon">
            <img height="23" src="@/assets/Calling.png" alt="phone" />
          </div>
          <div class="text">
            <div class="message">
              <p>Voice Balance</p>
            </div>
            <div class="amount">
              <div class="total">
                <p class="_total">
                  {{
                    ballance.voice_balance
                      ? voiceSecondsToTime(ballance.voice_balance)
                      : "00:00:00"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <button title="help" id="help" class="mr-2" @click="openHelp = true">
          <img
            width="27px"
            src="@/assets/help.svg"
            alt="help icon"
            class="help-icon-main"
          />
        </button>
        <div class="" id="#v-step-7">
          <el-dropdown class="drop-down-main notranslate">
            <!-- <el-button :popper-append-to-body='false' id="profile" :title="`${currentUser && currentUser.name}`">
              {{ currentUser.name && currentUser.name.slice(0, 15) }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button> -->
            <!-- New Get UserName from storage november 2022 -->
            <el-button
              :popper-append-to-body="false"
              id="profile"
              :title="userNameComputed"
              class="notranslate"
            >
              {{ userNameComputed
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="drop-down-menu">
              <el-dropdown-item class="drop-down-element">
                <button
                  class="_btn"
                  @click="
                    $router.push('/profile').catch((err) => {
                      if (err.name !== 'NavigationDuplicated') throw err;
                    })
                  "
                >
                  {{ $t("dashboard.dropdown.profile") }}
                </button>
              </el-dropdown-item>
              <el-dropdown-item class="drop-down-element">
                <button
                  class="_btn"
                  @click="
                    $router.push('/developer').catch((err) => {
                      if (err.name !== 'NavigationDuplicated') throw err;
                    })
                  "
                >
                  {{ $t("dashboard.dropdown.developer") }}
                </button>
              </el-dropdown-item>
              <el-dropdown-item class="drop-down-element">
                <button
                  class="_btn"
                  @click="
                    $router.push('/referral-settings').catch((err) => {
                      if (err.name !== 'NavigationDuplicated') throw err;
                    })
                  "
                >
                  {{ $t("dashboard.dropdown.referral") }}
                </button>
              </el-dropdown-item>
              <el-dropdown-item
                class="drop-down-element"
                v-if="!userFrancophone"
              >
                <el-badge
                  style="width: 100%; text-align: left; padding: 0 !important"
                >
                  <button
                    class="_btn _market_place"
                    @click="
                      $router.push('/market-place').catch((err) => {
                        if (err.name !== 'NavigationDuplicated') throw err;
                      })
                    "
                  >
                    {{ $t("dashboard.dropdown.market_place") }}
                    <el-tag
                      size="mini"
                      type="danger"
                      effect="dark"
                      class="is-new"
                      >New!</el-tag
                    >
                  </button>
                </el-badge>
              </el-dropdown-item>
              <el-dropdown-item class="drop-down-element">
                <button class="_btn" @click="logout">
                  {{ $t("dashboard.dropdown.logout") }}
                </button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="__toggle_icons">
        <div>
          <changeAppLanguage
            v-if="checkIfMobile"
            style="scale: 0.75; position: relative; top: 0.4rem"
          />
        </div>
        <div>
          <button id="icon_bars" class="" @click="showSideMenu = !showSideMenu">
            <img
              v-if="!showSideMenu"
              width="40px"
              height="50px"
              src="../../../assets/menu (1).png"
              alt="menu icon"
            />
            <img
              v-else
              width="40px"
              height="40px"
              src="../../../assets/hamburger.png"
              alt="hamburger icon"
            />
          </button>
          <button class="__mini_side_bar_toggle" @click="toggleMiniMenu">
            <img
              height="40px"
              width="40px"
              src="../../../assets/dots.png"
              alt="three dots menu icon"
            />
          </button>
        </div>
      </div>
    </div>

    <!--- Mini Menu -->
    <div id="mini_menu" v-if="showMiniMenu" class="__mini_side_bar">
      <button title="help" id="help" class="mr-2" @click="openHelp = true">
        <img width="27x" src="@/assets/help.svg" alt="help icon" />
      </button>

      <div class="" id="#v-step-7">
        <el-dropdown>
          <el-button id="profile" :title="`${currentUser && currentUser.name}`">
            {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <button
                class="_btn"
                @click="
                  $router.push('/profile').catch((err) => {
                    if (err.name !== 'NavigationDuplicated') throw err;
                  })
                "
              >
                {{ $t("dashboard.dropdown.profile") }}
              </button>
            </el-dropdown-item>
            <el-dropdown-item>
              <button
                class="_btn"
                @click="
                  $router.push('/referral-settings').catch((err) => {
                    if (err.name !== 'NavigationDuplicated') throw err;
                  })
                "
              >
                {{ $t("dashboard.dropdown.referral") }}
              </button>
            </el-dropdown-item>
            <el-dropdown-item>
              <button
                class="_btn"
                @click="
                  $router.push('developer').catch((err) => {
                    if (err.name !== 'NavigationDuplicated') throw err;
                  })
                "
              >
                {{ $t("dashboard.dropdown.developer") }}
              </button>
            </el-dropdown-item>
            <el-dropdown-item>
              <button class="_btn" @click="logout">Logout</button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- Mini Menu -->
    <div class="mnotify__main_dashboard_main_content">
      <div
        class="mnotify__main_dashboard_main_content_side_nav"
        :class="{
          show_side_menu: showSideMenu,
          collapse_sideNav: collapseSideMenuItems,
        }"
      >
        <button class="close_modal" @click="showSideMenu = !showSideMenu">
          <img
            width="30px"
            height="30px"
            src="../../../assets/close.png"
            alt="close icon"
          />
        </button>
        <!-- TOGGLE SIDE MENU -->
        <button
          v-if="collapseSideMenuItems"
          class="toggleMenu"
          @click="collapseSideMenu(false)"
        >
          <img
            width="20px"
            height="20px"
            src="../../../assets/fast-forward.png"
            alt="foward arrow icon"
          />
        </button>
        <button
          v-if="!collapseSideMenuItems"
          class="toggleMenu"
          @click="collapseSideMenu(true)"
        >
          <img
            width="20px"
            height="20px"
            src="../../../assets/fast-backward.png"
            alt="backward arrow icon"
          />
        </button>
        <!-- balance on small screen -->

        <div id="balance_sm" v-if="!collapseSideMenuItems">
          <div
            class="records notranslate"
            style="cursor: pointer"
            @click="
              $router.push('/wallet').catch((err) => {
                if (err.name !== 'NavigationDuplicated') throw err;
              })
            "
            v-if="logintype == 'sms'"
          >
            <div class="mobile-balance-info">
              <div class="mobile-sms-balance">
                <p style="font-weight: bold">Non-Expiry SMS</p>
                <p class="sms-balance notranslate">
                  <!-- {{
                    balance.sms_balance
                      ? balance.sms_balance.toLocaleString()
                      : 0
                  }} -->
                  {{ combinedBalance }}
                </p>
              </div>
              <div class="mobile-bonus">
                <p style="font-weight: bold">Expiry SMS</p>
                <p class="sms-bonus notranslate">
                  {{
                    balance.sms_bonus ? balance.sms_bonus.toLocaleString() : 0
                  }}
                </p>
                <p class="expiry-mobile notranslate">
                  Expiry: {{ expireDate }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="records"
            style="cursor: pointer"
            @click="
              $router.push('/wallet').catch((err) => {
                if (err.name !== 'NavigationDuplicated') throw err;
              })
            "
            v-else
          >
            <div class="icon">
              <img height="23" src="@/assets/Calling.png" alt="phone icon" />
            </div>
            <div class="text">
              <div class="message">
                <p>Voice Balance</p>
              </div>
              <div class="amount voice-amount-container">
                <div class="total voice-amount">
                  <p class="_total">
                    {{
                      balance.voice_balance
                        ? voiceSecondsToTime(balance.voice_balance)
                        : "00:00:00"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End balance on small screen -->

        <div
          :class="[collapseSideMenuItems ? 'collapse_sideNav_inner' : '']"
          class="__nav__inner mobile-inner"
        >
          <div class="topNav">
            <ul class="side_nav_list">
              <li
                :class="[collapseSideMenuItems ? 'nav_list_collapse' : '']"
                class="side_nav_list_item notranslate"
                v-for="menuItem in menutItems"
                :key="menuItem.id"
              >
                <div
                  class="side_nav_list_item_container needs_pointer"
                  :id="menuItem.tourID"
                  @click="redirect(menuItem.url)"
                  :class="[
                    $route.path.includes(menuItem.active)
                      ? 'user_nav_active'
                      : '',
                  ]"
                >
                  <div
                    :class="[collapseSideMenuItems ? 'expand-logo' : '']"
                    class="side_nav_list_item_logo"
                  >
                    <img
                      :src="
                        $route.path.includes(menuItem.active)
                          ? menuItem.activeIcon
                          : menuItem.icon
                      "
                      alt=""
                      :class="`icon_${menuItem.name}`"
                    />
                  </div>
                  <div
                    class="side_nav_list_item_text"
                    v-if="!collapseSideMenuItems"
                  >
                    <button
                      v-if="
                        menuItem.name == 'Wallet' ||
                        menuItem.name == 'Portefeuille'
                      "
                      type="button"
                      style="text-wrap: nowrap"
                    >
                      {{ menuItem.name }}
                      <span class="main-wallet-badge">
                        <span
                          class="ml-2 text-light badge bg-success contains-badge"
                        >
                          {{ walletBalance }}
                          <!-- <span class="says-new">
                            New
                          </span> -->
                        </span>
                      </span>
                    </button>
                    <button
                      class="send-messages-new-button"
                      v-else-if="menuItem.name == 'Send Messages'"
                    >
                      {{ menuItem.name }}
                      <!-- <span
                        class="ml-2 text-light position-fixed badge bg-success contains-badge new-international">
                        New
                      </span> -->
                    </button>

                    <button
                      v-else-if="menuItem.name == 'Help'"
                      @click="redirectToTawk"
                      class="is_button_container"
                    >
                      Help
                    </button>
                    <p class="menu_text shift-up" v-else>
                      {{ menuItem.name }}
                    </p>
                  </div>
                </div>
                <!-- <div class="sub_list_items" v-if="menuItem.subLinks">
                      <ul>
                        <li v-for="subLink in menuItem.subLinks" :key="subLink.id">
                          <div class="sub_link_item needs_pointer"  @click="$router.push(subLink.url)" >
                            <p :class="[$route.path.includes(subLink.active) ? 'reporting_active' :'']">
                              {{
                                subLink.name
                              }}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div> -->
              </li>
            </ul>
            <!-- new switch_channel -->
            <div v-if="collapseSideMenuItems" class="switch_channel_content">
              <p class="text-center collapsed-switch-channel">
                Switch <br />Channel
              </p>
              <div class="text-center toggle_button">
                <button
                  class="notranslate"
                  :class="[
                    subLink.active == channel ? 'toggle_channe_active' : '',
                  ]"
                  v-for="subLink in channeMenuItems[0].subLinks"
                  :key="subLink.id"
                  @click="toggleChannel(subLink.active, subLink.url)"
                >
                  <img
                    class="_channel_image"
                    :src="
                      subLink.active == channel
                        ? subLink.activeIcon
                        : subLink.icon
                    "
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            class="bottomNav contains-channel-items"
            id="bottonNavigation"
            v-if="!collapseSideMenuItems"
          >
            <ul class="side_nav_list">
              <li
                class="side_nav_list_item notranslate"
                v-for="menuItem in channeMenuItems"
                :key="menuItem.id"
              >
                <div class="side_nav_list_item_container">
                  <div class="side_nav_list_item_text switch-channel-full-text">
                    <p>
                      {{ menuItem.name }}
                    </p>
                  </div>
                </div>
                <div
                  class="sub_list_items needs_pointer notranslate"
                  v-if="menuItem.subLinks"
                >
                  <ul>
                    <li
                      class="notranslate"
                      v-for="subLink in menuItem.subLinks"
                      :key="subLink.id"
                      @click="toggleChannel(subLink.active, subLink.url)"
                    >
                      <div
                        class="sub_link_item subItem_with_icon notranslate"
                        :class="[
                          subLink.active == channel ? 'active_channel' : '',
                        ]"
                        style="padding-inline: 10px"
                      >
                        <img
                          :src="
                            subLink.active == channel
                              ? subLink.activeIcon
                              : subLink.icon
                          "
                          alt=""
                        />
                        <p
                          class="menu_text menu-text-in-switch"
                          v-if="!collapseSideMenuItems"
                        >
                          {{ subLink.name }}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>

          <div class="__copy_right" v-if="!collapseSideMenuItems">
            <p class="notranslate">(c) mNotify {{ getFullYear }}</p>
          </div>
        </div>
      </div>

      <div
        :class="[collapseSideMenuItems ? 'collapse_main_content_width' : '']"
        class="mnotify__main_dashboard_main_content_main"
        @click="openHelp = false"
      >
        <router-view> </router-view>
      </div>
      <div class="help_menu" v-if="openHelp">
        <div class="help_wrapper">
          <div class="_main_help_menu">
            <div class="_back">
              <button
                v-if="selectedHelp || popularTopics || featureRequest"
                @click="checkSwitchHelpCenter"
              >
                <img
                  src="../../../assets/Arrow - Left.svg"
                  alt="left arrow icon"
                />
              </button>
              <p class="__helpt_text">
                {{
                  !selectedHelp && !popularTopics && !featureRequest
                    ? "Help"
                    : selectHelpContent.header
                }}
              </p>
            </div>
            <a
              target="_blank"
              class="_visit_help"
              href="https://mnotify.tawk.help/"
            >
              {{ $t("dashboard.help.visit_help") }}
              <button><img src="@/assets/Group.svg" alt="" /></button>
            </a>
          </div>

          <div
            class="__list"
            v-if="!selectedHelp && !popularTopics && !featureRequest"
          >
            <div class="header">
              <h2 class="notranslate">Categories</h2>
            </div>

            <div class="__search_list_containr">
              <ul>
                <li>
                  <div @click="setPopulartopics">
                    <p class="notranslate">
                      {{ $t("dashboard.help.popular_topics") }}
                    </p>

                    <img
                      src="../../../assets/help_forward.svg"
                      alt="forward arrow icon"
                    />
                  </div>
                </li>
                <li>
                  <div @click="guidedSteps">
                    <p class="notranslate">
                      {{ $t("dashboard.help.guided_steps") }}
                    </p>
                    <img
                      src="../../../assets/help_forward.svg"
                      alt="forward arrow icon"
                    />
                  </div>
                </li>
                <li>
                  <div @click="handleSelectedHelpContent('Leave Feedback')">
                    <p class="notranslate">
                      {{ $t("dashboard.help.leave_feedback") }}
                    </p>
                    <img
                      src="../../../assets/help_forward.svg"
                      alt="forward arrow icon"
                    />
                  </div>
                </li>
                <!-- <li>
                  <div @click="handleSelectedCategory('Select a Category')">
                    <p>Select a Category</p>
                    <img src="../../../assets/help_forward.svg" alt="forward arrow icon">
                  </div>
                </li> -->
                <li>
                  <div @click="openFeatureRequest()">
                    <p>Feature Request</p>
                    <img
                      src="../../../assets/help_forward.svg"
                      alt="forward arrow icon"
                    />
                  </div>
                </li>
                <li>
                  <!-- <a target="_blank" href="https://mnotify.tawk.help/">
                    <div>
                      <p>Get help</p>
                      <img src="../../../assets/help_forward.svg" alt="">
                    </div>
                  </a> -->
                </li>
              </ul>
            </div>
          </div>

          <div class="select_help_section mt-2" v-if="selectedHelp">
            <div class="__feedback">
              <el-form
                :model="formData"
                :rules="rules"
                ref="ruleForm"
                @submit.prevent.native="checkEnter"
              >
                <div class="form_input_container">
                  <el-form-item label="Feedback" prop="feedback">
                    <el-input
                      type="textarea"
                      rows="10"
                      placeholder="Enter feedback body"
                      autocomplete="false"
                      v-model="formData.feedback"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="form_input_contain mt-5">
                  <el-form-item
                    label="Rate Us"
                    prop="rating"
                    required
                    style="margin: 0px auto !important; padding: 0px !important"
                    class="rate_us_component"
                  >
                    <el-rate
                      style="width: 100%"
                      v-model="formData.rating"
                      class="rate-stars"
                    ></el-rate>
                  </el-form-item>
                  <p
                    class="rating_error"
                    style="margin-top: -20px"
                    v-if="ratingError"
                  >
                    {{ ratingError }}
                  </p>
                  <p
                    class="alert alert-success mt-2"
                    v-if="success"
                    style="margin-top: -20px"
                  >
                    {{ success }}
                  </p>
                </div>
                <div class="mt-3">
                  <el-button
                    v-loading="loading"
                    @click="rateUs('ruleForm')"
                    style="width: 100%; background-color: #f7921c; color: #fff"
                  >
                    Submit
                  </el-button>
                </div>
              </el-form>
            </div>
          </div>
          <!-- FEATURE REQUEST -->
          <div class="select_help_section mt-2" v-if="featureRequest">
            <div class="__feedback">
              <el-form
                :model="requestForm"
                :rules="requestFormRules"
                ref="requestForm"
                @submit.prevent.native="checkEnter"
              >
                <div class="form_input_container">
                  <el-form-item label="Title" prop="title">
                    <el-input
                      type="text"
                      placeholder="Enter title"
                      autocomplete="false"
                      v-model="requestForm.title"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="form_input_container">
                  <el-form-item label="Description" prop="description">
                    <el-input
                      type="textarea"
                      rows="10"
                      placeholder="Enter descrition"
                      autocomplete="false"
                      v-model="requestForm.description"
                    ></el-input>
                  </el-form-item>
                </div>
                <p
                  class="alert alert-success mt-2"
                  v-if="success"
                  style="margin-top: -20px"
                >
                  {{ success }}
                </p>
                <div class="mt-3">
                  <el-button
                    v-loading="loading"
                    @click="makeRequest('requestForm')"
                    style="width: 100%; background-color: #f7921c; color: #fff"
                  >
                    Submit
                  </el-button>
                </div>
              </el-form>
            </div>
          </div>
          <!-- POPLAR TOPICS -->
          <div class="select_help_section mt-2" v-if="popularTopics">
            <div class="__feedback">
              <div class="popular_topic_list_container">
                <ul class="popular_topic_list">
                  <li
                    class="popular_topic_list_item"
                    v-for="populartopic in popularTopicsData"
                    :key="populartopic.id"
                  >
                    <div
                      class="popular_topic_list_collapse_head"
                      @click="populartopic.collapse = !populartopic.collapse"
                    >
                      <p :class="[populartopic.collapse ? 'active' : '']">
                        {{ populartopic.topic }}
                      </p>
                      <img
                        v-if="populartopic.collapse"
                        src="../../../assets/arrow_up.svg"
                        alt=""
                      />
                      <img
                        v-if="!populartopic.collapse"
                        src="../../../assets/arrow_down.svg"
                        alt=""
                      />
                      <!-- add icon here -->
                    </div>
                    <div
                      class="popular_topic_list_collapse_main"
                      v-if="populartopic.collapse"
                    >
                      <p v-for="(item, key) in populartopic.data" :key="key">
                        {{ item }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-tour
        id="myTour"
        name="myTour"
        :steps="tourSteps"
        :callbacks="myCallbacks"
      >
        <slot :finish="finishTour">
          <!--Default slot {{ currentStep }}-->
        </slot>
      </v-tour>
      <!-- First using the app -->
      <div
        class="modal welcome-modal"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-body">
              <h2 class="__modal__header">
                Welcome to the redesigned mNotifty platform
              </h2>
              <div class="modal__image_container">
                <img src="../../../assets/modal_image.svg" />
              </div>
              <p class="we_redesign">
                Welcome to the new mNotify Experience! We have redesigned the
                platform and added new features to make your journey and process
                simpler and easier, and we would love to show you around.
              </p>
            </div>
            <div class="modal-buttons">
              <button
                type="button"
                class="myButton py-2 px-3 btn secondary"
                @click="startTour"
              >
                Yes, start tour
              </button>
              <button
                type="button"
                class="myButton py-2 px-3 primary"
                @click="closeModal()"
              >
                No, skip tour
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CALL MODAL HERE -->
    <UpdateProfileModal
      @closeX="closeX"
      @updateProfile="updateProfile"
      ref="messageModal"
    />
    <leave_feed
      @feedback="confirmFeedback"
      :visible="showFeedback"
      v-if="showFeedbackModalComputed"
      ref="leaveFeedbackModal"
    />
    <!-- <select_category @categories="confirmCategory" :visible="showCategories" v-if="showCategoriesModalComputed"
      ref="leaveCategoriesModal" /> -->
    <!-- <npsSurvey /> -->
  </div>
</template>
<script>
  import $ from "jquery";
  import { mapGetters } from "vuex";
  import { populartopics } from "../../../helpers/populartopics";
  import storage from "../../../services/storage.js";
  import store from "@/state/store.js";
  import Calender from "../../../components/global/Calender.vue";
  import loginType from "@/helpers/loginType";
  import UpdateProfileModal from "./UpdateProfileModal";
  import leave_feed from "./dashboard-components/leave_feed.vue";
  import select_category from "./dashboard-components/select_category.vue";
  import moment from "moment";
  import npsSurvey from "./npsSurvey.vue";

  var Storage = new storage();
  export default {
    components: {
      Calender,
      UpdateProfileModal,
      leave_feed,
      select_category,
      npsSurvey,
    },
    data() {
      return {
        showEmailVerification: false,
        updateEmailModal: false,
        emailForm: {
          email: "",
          user_id: "",
        },
        showVerifyEmail: false,
        confirmationLoading: false,
        triggerRefreshOnComponent: 0,
        userName: "",
        startingTour: false,
        popularTopicsData: populartopics,
        popularTopics: false,
        currentUser: {},
        balance: {},
        whichStep: null,
        selectedCategory: "",
        myCallbacks: {
          onSkip: this.skipTour,
          onFinish: this.finishTour,
          onNextStep: (step) => (this.whichStep = step),
          onPreviousStep: (step) => (this.whichStep = step),
          onStart: (step) => (this.whichStep = step),
        },
        requestForm: {
          title: "",
          description: "",
        },
        requestFormRules: {
          title: [
            { required: true, message: "Title is required", trigger: "blur" },
          ],
          description: [
            {
              required: true,
              message: "Description is required",
              trigger: "blur",
            },
          ],
        },
        emailRules: {
          email: [
            { required: true, message: "Email is required", trigger: "blur" },
            {
              type: "email",
              message: "Please enter a valid email",
              trigger: "blur",
            },
          ],
        },
        steps: [
          {
            target: "#v-step-0", // We're using document.querySelector() under the hood
            header: {
              title: "Overview",
            },
            content: `Overview gives you a general summary of your account including your billing summary and your recent campaigns.`,
            params: {
              enableScrolling: false,
              placement: "right",
              highlight: true,
            },
          },
          {
            target: "#v-step-1", // We're using document.querySelector() under the hood
            header: {
              title: "Messages",
            },
            content: `Send messages, schedule and create message templates for later use here.`,
            params: {
              enableScrolling: false,
              placement: "right",
              highlight: true,
            },
          },
          {
            target: "#v-step-2", // We're using document.querySelector() under the hood
            header: {
              title: "Contacts",
            },
            content: `Add, remove, and create new groups here, you can also view existing groups.`,
            params: {
              enableScrolling: false,
              placement: "right",
              highlight: true,
            },
          },
          {
            target: "#v-step-3", // We're using document.querySelector() under the hood
            header: {
              title: "Birthday",
            },
            content: `Never forget a birthday! Enable this new feature to send voice or text messages to your contacts on their birthdays.`,
            params: {
              enableScrolling: false,
              placement: "right",
              highlight: true,
            },
          },
          {
            target: "#v-step-4", // We're using document.querySelector() under the hood
            header: {
              title: "Wallet",
            },
            content: `Purchase SMS and VOICE packages by loading up your wallet beforehand. Start by topping up your wallet, then choose a bundle from there.`,
            params: {
              enableScrolling: false,
              placement: "right",
              highlight: true,
            },
          },
          {
            target: "#v-step-5", // We're using document.querySelector() under the hood
            header: {
              title: "Reporting",
            },
            content: `Learn how many recipients got messages and export data from your campaign analytics`,
            params: {
              enableScrolling: false,
              placement: "right",
              highlight: true,
            },
          },
        ],
        openHelp: false,
        isEnglish: true,
        showSideMenu: false,
        selectedHelp: false,
        ratingError: "",
        success: "",
        loading: false,
        showProfile: false,
        showMiniMenu: false,
        featureRequest: false,
        selectHelpContent: {
          header: "",
        },
        formData: {
          feedback: "",
          categories: "",
          body: "",
          rating: 0,
        },
        rules: {
          body: [
            {
              required: true,
              message: "Body is required",
              trigger: ["blur", "change"],
            },
          ],
          feedback: [
            { required: true, message: "Title is required", trigger: "blur" },
          ],
        },
        // Trigger this value to show modal itself
        showFeedback: false,
        showCategories: false,
        showingMessage: null,
      };
    },

    computed: {
      dynamicWidthEmail() {
        if (screen.width < 768) {
          return "98%";
        } else {
          return "50%";
        }
      },
      checkIfMobile() {
        return screen.width < 768;
      },
      ballance() {
        return store.getters["wallet/ballance"];
      },
      combinedBalance() {
        if (this.ballance.sms_balance === undefined) {
          return 0;
        }
        return (
          (
            this.ballance.sms_balance + this.ballance.sms_bonus
          ).toLocaleString() || 0
        );
      },
      userNameComputed() {
        return this.userName;
      },
      currentLanguage() {
        return this.$store.getters["language/currentLanguage"];
      },
      walletBalance() {
        //  if currentLangaueg is fr return wallet balance in french and in
        const walletBalance = parseFloat(this.ballance.wallet);
        if (isNaN(walletBalance)) {
          return 0;
        }
        const country = Storage.getSavedState("currentUser").country;
        const isFrancophoneCountry = country === "CI" || country === "SN";
        return walletBalance.toLocaleString(
          isFrancophoneCountry ? "fr-CI" : "en-US",
          {
            style: "currency",
            currency: isFrancophoneCountry ? "CFA" : "GHS",
          }
        );
      },
      expireDate() {
        if (this.ballance.expiry_date) {
          return this.ballance.expiry_date.slice(0, 10);
        }
      },
      ...mapGetters("uistate", ["collapseSideMenuItems"]),
      tourSteps() {
        if (loginType() === "sms") {
          this.steps.push({
            target: "#v-step-6",
            header: {
              title: "Sender ID",
            },
            content: `Sender ID explains where the message originated from. Every message must have a sender id. Register one now.`,
            params: {
              enableScrolling: false,
              placement: "right",
              highlight: true,
            },
          });
        }
        if (this.$router.currentRoute.name !== "overview") {
          // remove last step
          this.steps.pop();
        }
        return this.steps;
      },
      logintype() {
        return loginType();
      },
      channel() {
        let loginType = Storage.getSavedState("loginType");
        if (!loginType) return "";
        return loginType;
      },
      getFullYear() {
        return new Date().getFullYear();
      },
      isLast() {
        return this.currentStep === this.steps.length - 1;
      },
      showFeedBackComputed() {
        return store.getters["modal/getShowFeedback"];
      },
      showFeedbackModalComputed() {
        return store.getters["modal/getShowFeedbackModal"];
      },
      showCategoriesComputed() {
        return store.getters["modal/getShowCategories"];
      },
      showCategoriesModalComputed() {
        return store.getters["modal/getShowCategoriesModal"];
      },
      userInfo() {
        return store.getters["auth/currentUser"].profile_type;

        // profile_type 1 is corporate 2 is individual
        // return store.getters["auth/currentUser"].country == "CI" ||
        //   store.getters["auth/currentUser"].country == "SN"
        //   ? 1
        //   : 2;
      },
      userFrancophone() {
        const user = Storage.getSavedState("currentUser");
        return user.country === "CI" || user.country === "SN";
      },
      menutItems() {
        return [
          {
            id: "1",
            name: this.$t("dashboard.menuItems.overview") || "Overview",
            url: "/overview",
            icon: require("../../../assets/Category_light.svg"),
            activeIcon: require("../../../assets/Category.svg"),
            active: "/overview",
            tourID: "v-step-0",
          },
          loginType() === "sms"
            ? {
                id: "2",
                name:
                  this.$t("dashboard.menuItems.send_message") || "Send Message",
                url: "/sms/messages",
                icon: require("../../../assets/default.svg"),
                activeIcon: require("../../../assets/message_active.svg"),
                active: "messages",
                tourID: "v-step-1",
              }
            : {
                id: "2",
                name: this.$t("dashboard.menuItems.send_voice") || "Send Voice",
                url: "/voice/messages",
                icon: require("@/assets/calling__default.svg"),
                activeIcon: require("@/assets/Calling.svg"),
                active: `message`,
                tourID: "v-step-1",
              },
          {
            id: "3",
            name: this.$t("dashboard.menuItems.contacts") || "Contacts",
            url: "/user/contacts",
            icon: require("../../../assets/Profile.svg"),
            activeIcon: require("../../../assets/Profile_active.svg"),
            active: "/user/contacts",
            tourID: "v-step-2",
          },
          {
            id: "4",
            name: this.$t("dashboard.menuItems.birthday_app") || "Birthday App",
            url: "/birthday-settings/save",
            icon: require("../../../assets/birthday.svg"),
            activeIcon: require("../../../assets/birth_day_active.svg"),
            active: "birthday-settings",
            tourID: "v-step-3",
          },
          {
            id: "5",
            name: this.$t("dashboard.menuItems.wallet") || "Wallet",
            url: "/wallet",
            icon: require("../../../assets/Wallet.svg"),
            activeIcon: require("../../../assets/Wallet_active.svg"),
            active: "/wallet",
            tourID: "v-step-4",
          },
          {
            id: "7",
            name: this.$t("dashboard.menuItems.campaign_history") || "Campaign",
            url: "/sms/campaign",
            icon: require("../../../assets/reporting.svg"),
            activeIcon: require("../../../assets/__reporting.svg"),
            iconDown: require("../../../assets/report__arrow.svg"),
            active: "campaign",
            tourID: "v-step-5",
            subLinks: [
              {
                id: "8",
                name: "Campaign history",
                url: "/sms/campaign",
                active: "history",
              },
              {
                id: "9",
                name: "Delivery report",
                url: "/sms/campaign/delivery_report",
                active: "delivery_report",
              },
            ],
          },
          {
            id: "10",
            name: this.$t("dashboard.menuItems.get_help") || "Get Help",
            icon: require("../../../assets/help.svg"),
            url: "mHelp",
          },
        ].filter((item) => {
          if (item.id === "10" && this.userFrancophone) {
            return false;
          }
          return true;
        });
      },
      channeMenuItems() {
        return [
          {
            id: "7",
            name: this.$t("dashboard.menuItems.switch_channel"),
            url: "",
            icon: require("@/assets/Notification (1).svg"),
            activeIcon: require("@/assets/Notification.svg"),
            iconDown: require("@/assets/report__arrow.svg"),
            subLinks: [
              {
                id: "8",
                name: this.$t("dashboard.menuItems.channel_sms"),
                tag: "sms",
                url: "/sms/messages",
                active: "sms",
                icon: require("@/assets/sms_default.svg"),
                activeIcon: require("@/assets/sms_active.svg"),
              },
              {
                id: "9",
                name: this.$t("dashboard.menuItems.channel_voice"),
                tag: "voice",
                url: "/voice/messages",
                active: "voice_messages",
                icon: require("@/assets/calling__default.svg"),
                activeIcon: require("@/assets/Calling.svg"),
              },
            ].filter((item) => {
              if (item.id === "9" && this.userFrancophone) {
                return false;
              }
              return true;
            }),
          },
        ];
      },
    },
    methods: {
      updateProfile() {
        let element = this.$refs.messageModal.$el;
        this.userName = store.getters["auth/currentUser"].name;
        $(element).modal("hide");
        this.openModal();
      },
      showModal() {
        let element = this.$refs.messageModal.$el;
        $(element).modal({
          backdrop: "static",
          keyboard: false,
          show: true,
        });
        this.showProfile = false;
      },
      closeX() {
        let element = this.$refs.messageModal.$el;
        $(element).modal("hide");
        this.showProfile = true;
      },
      initMethods() {
        store
          .dispatch("wallet/accountBalance")
          .then((res) => (this.balance = res.data.data));
      },
      makeRequest(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true;
            store
              .dispatch("feedback/featureRequest", this.requestForm)
              .then((response) => {
                this.success = "Request submitted successfully.";
                this.requestForm.title = "";
                this.requestForm.description = "";
              })
              .catch((err) => {
                if (err.response?.data == undefined) {
                  this.groupError = "Network error try again";
                }
              })
              .finally(() => (this.loading = false));
          } else {
            return false;
          }
        });
      },
      openFeatureRequest() {
        this.featureRequest = true;
      },
      collapseSideMenu(payload) {
        sessionStorage.setItem("isCollapsed", payload);
        store.dispatch("uistate/setcollapseSideMenuItems", payload);
      },
      toggleMiniMenu() {
        if (this.showMiniMenu) {
          this.showMiniMenu = false;
        } else {
          this.showMiniMenu = true;
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      },
      redirect(url) {
        if (this.$router.history.current.path === url) {
          return;
        }

        if (url == "mHelp") {
          window.open("https://mnotify.tawk.help/", "_blank");
          this.$mixpanel.track("Sidebar Help");
          if (this.$store.getters["auth/currentUser"].country === "CI") {
            this.$mixpanelFranco.track("Sidebar Help");
          }
          return;
        }
        this.$router.push(url).catch((err) => {});
      },
      voiceSecondsToTime(sec) {
        let hours = Math.floor(sec / 3600);
        let minutes = Math.floor((sec - hours * 3600) / 60);
        let seconds = sec - hours * 3600 - minutes * 60;
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        return hours + ":" + minutes + ":" + seconds;
      },
      clearIsFirstTimeLogin() {
        let Storage = new storage();
        Storage.clearSavedData("isFirstTimeLogin");
      },
      toggleUserStatus() {
        store.dispatch("auth/toggleUserStatus").then(() => {
          this.clearIsFirstTimeLogin();
        });
      },
      checkSwitchHelpCenter() {
        if (this.selectedHelp) {
          this.selectedHelp = false;
        }
        if (this.popularTopics) {
          this.popularTopics = false;
        }
        if (this.featureRequest) {
          this.featureRequest = false;
        }
      },
      guidedSteps() {
        this.$tours["myTour"].start();
        this.startingTour = true;
        this.openHelp = false;
      },
      checkProfileSettings() {
        // let Storage = new storage()
        // const isFirstTimeLogin = Storage.getSavedState('isFirstTimeLogin')
        // if(isFirstTimeLogin && isFirstTimeLogin.from == 'register') {
        //   this.showProfile = true
        // } else {
        //   SProfileSettingstorage.clearSavedData('isFirstTimeLogin')
        // }
      },
      finishTour() {
        this.startingTour = false;
        this.$mixpanel.track("Finished Tour");
        if (this.$store.getters["auth/currentUser"].country === "CI") {
          this.$mixpanelFranco.track("Finished Tour");
        }
        return this.startingTour;
      },
      skipTour(step) {
        this.$mixpanel.track("Did not finish tour", {
          step: this.steps[this.whichStep].content,
          "step-count": this.whichStep,
        });
        if (this.$store.getters["auth/currentUser"].country === "CI") {
          this.$mixpanelFranco.track("Did not finish tour");
        }
        this.startingTour = false;
        // window.location.reload()
      },
      toggleChannel(active, url) {
        let Storage = new storage();
        Storage.saveAndDelete("loginType", active);
        if (this.$router.history.current.path == url) {
          return;
        }
        this.$router
          .push(url)
          .then(() => {
            this.$router.go();
            window.location.reload();
          })
          .catch((err) => {});
      },
      startTour() {
        this.$tours["myTour"].start();
        $("#exampleModal").modal("hide");
      },
      closeModal() {
        $("#exampleModal").modal("hide");
        this.showProfile = false;
        this.startingTour = false;
        this.refreshView();
        this.$mixpanel.track("Skip Tour");
        if (this.$store.getters["auth/currentUser"].country === "CI") {
          this.$mixpanelFranco.track("Skip Tour");
        }
      },
      openModal() {
        $("#exampleModal").modal({
          backdrop: "static",
          keyboard: false,
          show: true,
        });
        this.startingTour = true;
      },
      rateUs(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true;
            if (this.formData.rating === 0) {
              this.ratingError = "Rating is required.";
              this.loading = false;
              return;
            } else {
              this.ratingError = "";
            }
            store
              .dispatch("feedback/sendFeedback", this.formData)
              .then((response) => {
                this.success = "Feedback submitted successfully.";
                this.formData.body = "";
                this.formData.feedback = "";
                this.formData.rating = "";
              })
              .catch((err) => {
                if (err.response?.data == undefined) {
                  this.groupError = "Network error try again";
                }
              })
              .finally(() => (this.loading = false));
          } else {
            if (this.formData.rating == 0) {
              this.ratingError = "Rating is required.";
            } else {
              this.ratingError = "";
            }
            return false;
          }
        });
      },
      setPopulartopics() {
        this.popularTopics = true;
        this.selectHelpContent.header = "Popular Topics";
      },
      getHelp() {
        this.$router.push("/help").catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      },
      handlePopularTopics() {
        this.popularTopics = true;
      },
      handleSelectedHelpContent(title) {
        this.selectedHelp = true;
        this.selectHelpContent.header = title;
      },
      handleSelectedCategory(title) {
        this.selectedHelp = true;
        this.selectHelpContent.header = title;
      },
      logout() {
        let Storage = new storage();
        Storage.clearSavedData("token");
        Storage.clearSavedData("currentUser");
        // Remove username after logout
        localStorage.removeItem("userName");
        localStorage.removeItem("profileSetup");
        sessionStorage.removeItem("emailVerified");
        window.location.reload();
      },
      refreshView() {
        this.triggerRefreshOnComponent = this.triggerRefreshOnComponent + 1;
      },
      redirectToTawk() {
        window.open("https://mnotify.tawk.help/", "_blank");
      },
      confirmFeedback() {
        this.openHelp = true;
        this.handleSelectedHelpContent("Leave Feedback");
        // Dispatch back to false
        store.dispatch("modal/showFeedback", false);
      },
      confirmCategory() {
        this.openHelp = true;
        this.handleSelectedCategory("Select Category");
        // Dispatch back to false
        store.dispatch("modal/showCategories", false);
      },

      checkUserEmailVerified() {
        const lastDisplayTimestamp = localStorage.getItem(
          "email-verify-modal-last-displayed"
        );
        const oneDayTimestamp = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
        if (
          !lastDisplayTimestamp ||
          Date.now() - lastDisplayTimestamp >= oneDayTimestamp
        ) {
          // Check if user has verified email
          let Storage = new storage();
          let user = Storage.getSavedState("currentUser");
          this.emailForm.user_id = user.user_id;
          store
            .dispatch("auth/getEmailVerificationStatus", {
              user_id: this.emailForm.user_id,
            })
            .then((res) => {
              sessionStorage.setItem("emailVerified", res.data.success);
            })
            .catch((err) => {
              if (err.response.data.success == false) {
                this.showEmailVerification = true;
                this.emailForm.email = err.response.data.message.email;
                localStorage.setItem(
                  "email-verify-modal-last-displayed",
                  Date.now()
                );
              }
            });
        } else {
          return;
        }
      },
      sendEmailForVerification() {
        this.$refs["emailForm"].validate((valid) => {
          if (valid) {
            this.showVerifyEmail = true;
            this.updateEmailModal = false;
            store
              .dispatch("auth/sendEmailForVerification", this.emailForm)
              .then((response) => {
                this.success = "Verification email sent successfully.";
              })
              .catch((err) => {
                if (err.response?.data == undefined) {
                  this.groupError = "Network error try again";
                }
              })
              .finally(() => (this.confirmationLoading = false));
          } else {
            return false;
          }
        });
      },
      checkedUserVerified() {
        this.confirmationLoading = true;
        store
          .dispatch("auth/getEmailVerificationStatus", {
            user_id: this.emailForm.user_id,
          })
          .then((res) => {
            this.$notify({
              title: "Success",
              message: "Email verified successfully.",
              type: "success",
            });
            this.showEmailVerification = false;
            this.showVerifyEmail = false;
            sessionStorage.setItem("emailVerified", res.data.success);
            localStorage.removeItem("verificationFromProfile");
          })
          .catch((err) => {
            if (err.response.data.success == false) {
              this.$notify({
                title: "Error",
                message: "Email not verified.",
                type: "error",
              });
            }
          })
          .finally(() => (this.confirmationLoading = false));
      },
      checkUserCameFromProfile() {
        if (localStorage.getItem("verificationFromProfile") == "true") {
          this.showVerifyEmail = true;
          return;
        }
        this.updateEmailModal = true; // this is the we have sent the email modal
      },
      goBackToChangeEmail() {
        this.showVerifyEmail = false;
        this.updateEmailModal = true;
      },

      showNotification(not_message) {
        if (document.querySelector(".banner")) {
          return;
        }
        this.$message({
          message: not_message,
          duration: 0,
          showClose: true,
          customClass: "banner",
          center: true,
          grouping: true,
          onClose: () => {
            sessionStorage.setItem("closedNotification", "true");
          },
          dangerouslyUseHTMLString: true,
        });
      },
    },

    created() {
      store
        .dispatch("wallet/accountBalance")
        .then((res) => (this.balance = res.data.data));
      // Set Nav Default
      var isTrueSet = sessionStorage.getItem("isCollapsed") === "true";
      store.dispatch("uistate/setcollapseSideMenuItems", isTrueSet);
    },
    beforeMount() {
      var Tawk_API = Tawk_API || {},
        Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/61f16f02b9e4e21181bc0788/1fqbhj98r";
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    },

    mounted() {
      //has seen notification
      store.dispatch("banner/getActiveNotifications").then((data) => {
        if (data) {
          if (moment(data.end_date).toDate() != moment()) {
            if (
              sessionStorage.getItem("closedNotification") == null ||
              sessionStorage.getItem("notMessage") != data.message
            ) {
              sessionStorage.setItem("notMessage", data.message);
              // check country code before showing notification
              if (
                data.country == Storage.getSavedState("currentUser").country
              ) {
                this.showNotification(data.message);
              }
              return;
              // this.showNotification(data.message);
            }
          }
        }
      });
      // Check if user has verified email
      if (
        sessionStorage.getItem("emailVerified") == null ||
        sessionStorage.getItem("emailVerified") == "false"
      ) {
        this.checkUserEmailVerified();
      }

      // Username store
      if (localStorage.getItem("profileSetup") == null) {
        store
          .dispatch("auth/getUserProfileStatus")
          .then((res) => {
            let userData = res.data.data;
            if (userData.profile_type === 2) {
              userData.name = `${userData.firstname} ${userData.lastname}`;
              this.userName = userData.name;
              localStorage.setItem("profileSetup", userData.profile_type);
            } else {
              this.userName = userData.name;
            }
            this.currentUser = userData;
          })
          .catch((err) => {
            if (err.response.status == 404) {
              this.showProfile = true;
            }
          });
      } else {
        this.userName = store.getters["auth/currentUser"].name;
        return;
      }
      // Removed Calling of getting username all the time
      var navbar = document.querySelector(".mnotify__main_dashboard_header");
      window.onscroll = function () {
        if (window.pageYOffset > 0) {
          navbar.classList.add("scrolled");
        } else {
          navbar.classList.remove("scrolled");
        }
      };
      if (this.currentUser.profile_type === 0) {
        this.showProfile = true;
      } else {
        this.userName = store.getters["auth/currentUser"].name;
        this.initMethods();
      }
    },

    watch: {
      showFeedBackComputed(val) {
        if (val == true) {
          this.confirmFeedback();
        }
      },
      // watch
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/styles/dashboard/main.scss";

  .banner {
    width: 100vw !important;
  }

  .toggleMenu {
    position: absolute;
    transition: all 1s;
    left: 15px;
  }

  .myBadge {
    min-width: 20px;
    max-width: 100%;
    height: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    background-color: #f7921c;
    padding: 0.4em;

    p {
      color: #fff;
      font-weight: bold;
    }
  }

  .scrolled {
    background-color: #fff;
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .secondary {
    background: #f7921c;
    box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #ffffff;
  }

  .primary {
    background: #f3f3f4;
    box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #484a4f;
    margin-left: 1em;
  }

  .we_redesign {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #8a8c8f;
    margin-top: 16px;
    margin: 16px 0;
    text-align: center;
  }

  .__modal__header {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #575761;
    margin-bottom: 16px;
    text-align: center;
  }

  .modal-content {
    padding: 1em;
  }

  .modal__image_container {
    background: #f5f5f5;
    border-radius: 12px;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 70%;
    }
  }

  .v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.1);
  }

  .update_profile {
    position: absolute;
    top: 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    z-index: 1000;
    width: 299px;
    height: 143px;

    button {
      background: #f7921c;
      box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      color: #ffffff;
      width: 145px;
      height: 41px;
    }

    .text___ {
      text-align: center;
      padding: 2em;

      p {
        font-family: "Graphik Regular";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;
        text-align: center;
        color: #484a4f;
      }

      button {
        background: #f7921c;
        box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        color: #ffffff;
        width: 100%;
        height: 41px;
        margin-top: 20px;
      }
    }
  }

  //overlayclass
  .OverLay {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
  }

  // popular topics
  .popular_topic_list_container {
    .popular_topic_list {
      margin: 0px;
      padding: 0px;
      list-style: none;

      .popular_topic_list_item {
        list-style: none;

        .popular_topic_list_collapse_head {
          width: 290.53px;
          height: 40px;
          background: rgba(246, 246, 246, 0.2);
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 0.5em;
          padding-right: 0.5em;
          cursor: pointer;

          p {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 13px;
            color: #484a4f;
          }
        }
      }

      .popular_topic_list_collapse_main {
        padding: 1em 0.3em;

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 140%;
          color: #484a4f;
          padding-bottom: 0.5em;
        }
      }
    }
  }

  .active {
    font-weight: 600 !important;
  }

  .new {
    background-color: #f56c6c;
    border-radius: 5px;
    width: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    position: fixed;
    left: 170px;
    padding-top: 0.1rem;
  }

  .says-new {
    background-color: #f56c6c;
    position: absolute;
    border-radius: 5px;
    padding: 0.2rem;
    font-size: 12px;
    bottom: 0rem;
    top: 0rem;
    line-height: 0.6rem;
    left: 100%;
  }

  .new-international {
    background-color: #f56c6c !important;
  }

  .help_menu {
    height: 100vh;
    // height: calc(68vmax + 2vh);
    box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
  }

  .help_wrapper {
    position: sticky;
    top: 3%;
    overflow-x: hidden;
  }

  .drop-down-main {
    padding: 0;
    margin: 0 auto;
  }

  .drop-down-element {
    // background-color: springgreen;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-inline: 0;
  }

  .drop-down-element * {
    box-sizing: border-box;
    // background-color: red;
    width: 100%;
    overflow: hidden;
    padding-inline: 1.5rem;
  }

  .mnotify__main_dashboard_main_content {
    transition: 0.5s ease;
  }

  .mnotify__main_dashboard_main_content_side_nav {
    transition: 0.5s ease;
    overflow: hidden;
  }

  .show_side_menu {
    transition: 0.5s ease;
  }

  .collapse_sideNav {
    transition: 0.5s ease;
  }

  .switch_channel_content {
    box-sizing: border-box;
    padding-top: 6.5rem;
  }

  .collapsed-switch-channel {
    // position: absolute;
    transition: opacity 0.5s ease;
    margin-top: -10rem;
  }

  .menu_text {
    // position: fixed;
    left: 69px;
    text-wrap: nowrap;
  }

  .shift-up {
    margin-top: -0.25rem;
  }

  .menu-text-in-switch {
    // position: fixed;
    left: 84px;
  }

  .switch-channel-full-text p {
    // position: fixed;
    width: 100%;
  }

  *:not(.el-dialog__wrapper) {
    transition: all 0.4s ease;
  }

  @media screen and (max-width: 820px) {
    body,
    html {
      overflow-y: auto;
    }

    .modal {
      overflow: auto;
    }
  }

  @media screen and (max-width: 820px) {
    .bottomNav {
      // background-color: red;
      position: relative;
      // top: -150px;
    }
  }

  .noOverlay {
    display: none;
  }

  // .modal{
  // TODO:figure out why i added this
  //   z-index: 100000;
  // }

  ._visit_help {
    position: relative;
    top: 6px;
  }

  .myBadge {
    background-color: transparent;
  }

  .is-balance-text {
    margin: 0 auto !important;
    font-size: clamp(0.7rem, 1.1vw, 1.5rem) !important;
    text-align: center !important;
    // background-color: red;
    width: 100% !important;
    color: black !important;
  }

  .text-container {
    display: flex;
    gap: 2rem;
    width: 100%;
    min-width: fit-content;
  }

  .text-container div p {
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  .text-container div {
    max-width: 100%;
    min-width: fit-content;
  }

  .records {
    min-width: 200px;
  }

  .sms-records {
    min-width: 100px;
  }
  .balance-header {
    min-width: 15rem;
    margin: 0 auto;
  }

  .record-title {
    font-size: clamp(0.7rem, 1.3ch, 1.1rem);
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  .record-amount {
    font-size: clamp(0.5rem, 0.87vw, 1.5rem);
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  .sms-bonus {
    border-radius: 14px;
    background-color: #f56c6c;
    color: white;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(0.5rem, 0.75vw, 1.5rem);
  }

  ._total {
    font-size: clamp(1rem, 1vw, 1.5rem) !important;
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  .contains-badge {
    // position: fixed !important;
    width: min-content !important;
  }

  .contains-channel-items {
    // position: fixed !important;
    width: min-content !important;
    // margin-top: 7rem;
    // top: 60%;
  }

  .mnotify_app__main_dashboard::-webkit-scrollbar {
    display: none;
  }

  .rate_us_component {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .rate-stars {
    position: relative;
    bottom: 0.25rem;
  }

  .update_email {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100%;
    // height: 100%;
    // background-color: rgba(0, 0, 0, 0.5);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .email-verification-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: white;
    padding: 1rem;
    border-radius: 10px;
  }

  .email-verification-text-container p {
    font-size: clamp(0.8rem, 1vw, 1.5rem);
    text-align: center;
    margin-block: 1rem;
  }

  .email-verification-text-container button {
    background-color: #f7921c;
  }

  .user-email {
    font-weight: 900;
    text-transform: initial;
    color: #f56c6c;
  }

  @media screen and (max-width: 820px) {
    #balance_sm {
      width: 100%;
      height: auto;
      margin-top: 4rem !important;
    }

    .message * {
      font-size: clamp(0.7rem, 0.85vw, 1.5rem) !important;
    }

    .total * {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px !important;
    }

    .myBadge {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      flex-direction: column;
    }

    .bonus-mobile {
      display: flex;
      font-size: 14px !important;
      color: #484a4f !important;
      position: relative;
      justify-content: flex-end;
      bottom: 2.5px;
      text-align: right;
    }

    .expiry-mobile {
      display: flex;
      font-size: 10px !important;
      color: #f56c6c !important;
      text-align: center;
    }

    .records {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 50px;
    }

    .message {
      display: flex;
      margin: 0 auto !important;
      width: 155px !important;
    }

    .amount {
      margin: 0 auto !important;
      width: 160px !important;
      min-height: 50px;
    }
  }

  .icon_Help {
    width: 25px !important;
    height: 25px !important;
    position: relative;
    right: 10px;
  }

  .icon_Envoyer {
    right: 0;
    width: 20px !important;
    height: 20px !important;
  }

  .logo_section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
    height: 100%;
    overflow: hidden;
    position: relative;
    right: 20px;
    // background-color: salmon;
  }

  .add_logo {
    width: max(15%, 190px);
    position: relative;
    right: 0;
    left: 10px;
    object-fit: cover;
    overflow: hidden;
    // background-color: red;
  }

  .verify-email-button {
    background-color: #f7921c;
  }

  .is-danger {
    background-color: #f56c6c;
    color: white;
  }

  .is-danger:hover {
    background-color: #f56c6c;
    color: white;
  }

  .confirm-email-text {
    font-size: clamp(0.8rem, 1vw, 1.5rem);
    text-align: center;
    margin-block: 1rem;
  }

  .update-email-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-block: 2rem;
  }

  .wallet-balance span {
    background-color: #31cd54;
    padding-inline: 0.5rem;
    border-radius: 20px;
    color: #fff;
  }

  .wallet-balance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: clamp(0.5rem, 0.85vw, 1.5rem);
  }

  .mobile-inner {
    overflow-y: scroll;
  }

  .switch_channel_mobile {
    display: none;
  }

  .send-messages-new-button {
    position: fixed;
    transform: translateY(-6px);
  }

  .center-all-modals {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ._market_place {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
  }

  .is-new {
    padding-inline: 0 !important;
    width: fit-content;
  }

  .side_nav_list {
    min-height: 100px;
    width: 100%;
  }

  .__nav__inner {
    height: 100% !important;
    overflow-y: auto !important;
    margin-right: 1rem;
  }

  .__nav__inner::-webkit-scrollbar {
    display: none;
    width: 2px;
    background-color: #f5f5f5;
  }
  .__nav__inner:hover::-webkit-scrollbar {
    display: block;
  }
  .__nav__inner::-webkit-scrollbar-thumb {
    background-color: #f7911c4a;
    border-radius: 10px;
  }

  @media screen and (max-width: 600px) {
    .logo_section {
      right: initial;
    }

    .add_logo {
      width: 23%;
    }
    .mobile-balance-info {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      padding-inline: 10px;
      gap: 1rem;
      // margin-bottom: 4.5rem;
    }
    .mobile-balance-info * {
      text-align: left !important;
      align-self: flex-start;
    }
    .sms-bonus,
    .wallet-balance {
      font-size: 11px !important;
      text-align: left !important;
    }

    .switch_channel_mobile {
      display: block;
    }
    .__nav__inner {
      margin-bottom: 1rem !important;
    }
  }

  @media screen and (max-width: 345px) {
    .logo_section {
      width: min(70%, 65%);
    }
  }

  @media screen and (max-width: 291px) {
    .logo_section {
      width: 60%;
    }
  }

  .help-icon-main {
    margin-inline: 1rem;
    position: relative;
    left: 20px;
  }
  button {
    width: fit-content !important;
  }

  ._btn {
    width: 100% !important;
  }
</style>
